import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/Layout"
import styled from "styled-components"
import { Rating } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import Img from "gatsby-image";

const Title = styled.h3`
  color: #9d036a;
  margin-bottom: 0;
  text-transform: capitalize;
`;

const Date = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #9d929b;
  font-size: 0.9rem;
`;

const Image = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  width: 200px;
  max-height=300px;
`;

const StyledRating = withStyles({
    iconFilled: {
        color: '#9d036a'
    },
    iconEmpty: {
        color: '#9d929b'
    }
})(Rating);

export default ({ data: { mdx } }) => {
  return (
    <Layout>
      <Title>{mdx.frontmatter.title}</Title>
      <Date>{mdx.frontmatter.date}</Date>
      {mdx.frontmatter.rating && <StyledRating defaultValue={mdx.frontmatter.rating} readOnly={true}/>}
      <Image> {mdx.frontmatter.thumbnail.childImageSharp && <Img fluid={mdx.frontmatter.thumbnail.childImageSharp.fluid} style={{maxHeight: '100%', maxWidth: '100%'}}/> } </Image>
      <MDXRenderer>{mdx.body}</MDXRenderer>
    </Layout>
  )
}

//

export const pageQuery = graphql`
  query TechPageQuery($id: String) {
    mdx(id: { eq: $id }) {
        frontmatter {
            date(formatString: "DD MMM YYYY")
            title
            rating
            thumbnail {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        id
        body
    }
  }
`
